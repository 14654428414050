import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image from '../../assets/images/image1.webp';
import mob from '../../assets/images/mob-bg.webp';

const Banner = () => {
  const { t } = useTranslation();
  const list = t('main.first.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={styles.bg}>
        <img src={image} alt="" className="desk_only" />
        <img src={mob} alt="" className="mob_only" />
      </div>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <Fade right><h1 className={styles.title}>{t('main.first.title-0')}</h1></Fade>
          <Fade left><h5 className={styles.subtitle}><Trans>{t('main.first.title-1')}</Trans></h5></Fade>
          <Fade left><div className={styles.text}><Trans>{t('main.first.text')}</Trans></div></Fade>
          <Fade right><div className="mt-btn"><a href="#contacts" className={styles.btn}>{t('btn.contactus')}</a></div></Fade>

          <div className={styles.list}>
            {list.map((item, index) => (
              <Fade bottom duration={500} delay={index * 1000 + 500} key={index}>
                <div className={styles.listItem}>
                  <div className={styles.listItemTitle}>{t(`${item.title}`)}</div>
                  <div className={styles.listItemText}>{t(`${item.text}`)}</div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;