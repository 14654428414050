import React, { useState } from "react";
import styles from './Slider.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import icon from '../../assets/images/arr-right.svg';
import image1 from '../../assets/images/slide1.webp';
import image2 from '../../assets/images/slide2.webp';
import image3 from '../../assets/images/slide3.webp';
import image4 from '../../assets/images/slide4.webp';
import image5 from '../../assets/images/slide5.webp';
import image6 from '../../assets/images/slide6.webp';
import image7 from '../../assets/images/slide7.webp';
import image8 from '../../assets/images/slide8.webp';

const Slider = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  const [active, setActive] = useState(0);
  const slides = t('main.seven.slides', { returnObjects: true });
  const images = [image1, image2, image3, image4, image5, image6, image7, image8];

  return (
      <section className={styles.wrap}>
        <div className={`${styles.container} container`}>
          <div className={styles.blockText}>
            <div className={styles.titlebg}>{t('main.seven.title')}</div>
            <div className={styles.title}><Trans>{t('main.seven.title1')}</Trans></div>
          </div>
          <div className={styles.sliderWrap}>
            <Swiper
              modules={[Navigation]}
              spaceBetween={19}
              slidesPerGroup={1}
              loop={false}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                769: {
                  slidesPerView: 1.2,
                },
                1024: {
                  slidesPerView: 2.2,
                }
              }}
              onInit={(e) => { setSwiper(e) }}
              onSlideChange={(e) => setActive(e.activeIndex)}
            >
              {slides.map((item, index) => (
                <SwiperSlide key={index} className={styles.sliderItem}>
                  <div className={styles.imgWrap}>
                    <img src={images[index]} alt="" />
                  </div>
                  <div className={styles.slideTitle}>{item.title}</div>
                  <div className={styles.slideList}>
                    {item.list.map((listItem, listIndex) => (
                      <div className={styles.slideListText} key={listIndex}><Trans>{listItem.title}</Trans></div>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.sliderBtns}>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnPrev} ${active === 0 ? styles.inactive : ''}`}
              onClick={() => swiper.slidePrev()}
            >
              <img src={icon} alt="Prev" />
            </button>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnNext} ${active === swiper.snapGrid?.length - 1 ? styles.inactive : ''}`}
              onClick={() => swiper.slideNext()}
            >
              <img src={icon} alt="Next" />
            </button>
          </div>
        </div>
      </section>
  );
};

export default Slider;