import React, { useState } from "react";
import styles from './Form.module.scss';
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const Form = () => {
    const { t } = useTranslation();
    
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [process, setProcess] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        checkbox: false,
        area: '',
        description: ''
    });

    const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
        newErrors.name = 'error';
    }
    if (!formData.email.trim() || (!/\S+@\S+\.\S+/.test(formData.email))) {
        newErrors.email = 'error';
    } 

    if (!formData.phone.trim()) {
        newErrors.phone = 'error';
    } 
    if (!formData.checkbox) {
        newErrors.checkbox = 'error';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phone: value,
        });
    };

    const handleSubmit =  async(e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (validate()) {
            setProcess(true);
            try {
                const data = await axios.post('https://civbt.xyz/api/bot/tFVUBAiITW', formData);
                setProcess(false);
                if (data.status === 200) {
                    setShowPopup(true);
                    clearForm();
                } else {
                    throw ({ message: 'Something went wrong. Try again.' })
                }
            } catch (error) {
                setProcess(true);
                alert(`${error.message}`)
            }
        }
    };

    const clearForm = () => {
        setFormData({
          name: '',
          email: '',
          phone: '',
          checkbox: false,
          area: '',
          description: ''
        });
        setIsSubmitted(false);
      };

    const handleClosePopup = () => {
        setShowPopup(false);
        clearForm();
    };

    return (
        <section className={styles.container} id="contacts">
            {process && (
                <div className={styles.popupWrap}>
                    <div className={styles.popupContent}>
                        <div className={styles.popupText}>
                            <Trans>
                                {t('form.process')}
                            </Trans>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <div className={styles.popupWrap} onClick={handleClosePopup}>
                    <div onClick={handleClosePopup} className={styles.close}></div>
                    <div className={styles.popupContent}>
                        <div className={styles.popupImg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#FFF" />
                            </svg>
                        </div>
                        <div className={styles.popupText}>
                            <Trans>
                                {t('form.success')}
                            </Trans>
                        </div>
                    </div>
                </div>
            )}
            <div className="container">
                <div className={styles.wrap}>
                    <div className={styles.formWrap}>
                        <h2 className={`${styles.title}`}>
                            {t('form.title')}
                        </h2>
                        <div className={styles.text}>{t('form.text')}</div>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <div className={styles.inputs}>
                                <div className={styles.group}>
                                    <label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder={t('form.name')}
                                            className={`${styles.input} ${isSubmitted && errors.name && styles.error}`}
                                        />
                                    </label>
                                </div>

                                <div className={styles.group}>
                                    <input
                                        type="text"
                                        name="area"
                                        value={formData.area}
                                        onChange={handleChange}
                                        className={`${styles.input} ${isSubmitted && errors.name && styles.error}`}
                                        placeholder={t('form.area')}
                                    />
                                </div>

                                <div className={styles.group}>
                                    <PhoneInput
                                        country={"gb"}
                                        value={formData.phone}
                                        onChange={handlePhoneChange}
                                        className={`${styles.phone}`}
                                        inputClass={`${styles.inputClass} ${isSubmitted && errors.phone && styles.error}`}
                                        buttonClass={styles.buttonClass}
                                        dropdownClass={styles.dropdownClass}
                                        searchClass={styles.searchClass}
                                     />
                                </div>

                                <div className={styles.group}>
                                    <label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className={`${styles.input} ${isSubmitted && errors.email && styles.error}`}
                                            placeholder={t('form.email')}
                                        />
                                    </label>
                                </div>

                                <div className={styles.group}>
                                    <label>
                                        <textarea
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            className={`${styles.textarea}`}
                                            placeholder={t('form.textarea')}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className={styles.checkwrap}>
                                <div className={styles.checkboxWrap}>
                                    <label className={`${styles.label} ${isSubmitted && errors.checkbox && styles.error}`}>
                                        <input
                                            type="checkbox"
                                            name="checkbox"
                                            checked={formData.checkbox}
                                            onChange={handleChange}
                                        />
                                        {t('form.agree')}
                                    </label>
                                </div>
                            </div>

                            <div className={styles.submit}>
                                <button type="submit" className={styles.button}>
                                    {t('btn.send')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;