import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/Banner/Banner';
import SecondD from '../components/SecondD/SecondD';
import Tabs from '../components/Tabs/Tabs';
import ThirdD from '../components/ThirdD/ThirdD';
import About from '../components/About/About';
import Slider from '../components/Slider/Slider';
import Form from '../components/Form/Form';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lexon Partners</title>
        <meta name="title" content="Lexon Partners: expert legal services for business & personal needs" />
        <meta property="og:title" content="Lexon Partners: expert legal services for business & personal needs" />
        <meta property="twitter:title" content="Lexon Partners: expert legal services for business & personal needs" />

        <meta name="description" content="Lexon Partners is a leading law firm offering expert legal solutions in corporate law, litigation, real estate, and personal legal matters. Trust our experienced attorneys to protect your interests with strategic, client-focused representation." />
        <meta property="og:description" content="Lexon Partners is a leading law firm offering expert legal solutions in corporate law, litigation, real estate, and personal legal matters. Trust our experienced attorneys to protect your interests with strategic, client-focused representation." />
        <meta property="twitter:description" content="Lexon Partners is a leading law firm offering expert legal solutions in corporate law, litigation, real estate, and personal legal matters. Trust our experienced attorneys to protect your interests with strategic, client-focused representation." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='main-page ovf-hidden'>
        <Header />
        <Banner />
        <SecondD />
        <Tabs />
        <ThirdD />
        <About />
        <Slider />
        <Form />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;