import React from 'react';
import { useTranslation } from "react-i18next";
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footerContent}>
          <div className={styles.leftSide}>
            <div className={styles.logoWrap}>
              <svg className={styles.headerLogo} xmlns="http://www.w3.org/2000/svg" width="45" height="47" viewBox="0 0 45 47" fill="none">
                <path d="M12.0188 46.1639L24.2832 7.15119H19.2127L7.4639 46.0968H0L13.8905 0H34.0337L21.7693 38.8968H37.8458V33.6234H26.5546L28.8092 26.4722H44.997V46.1639H12.0188Z" fill="#105739"/>
                <path d="M6.60357 44.9376H1.56052L14.7524 1.15942H32.4519L20.189 40.0562H39.0051V32.4642H28.1349L29.6588 27.6316H43.8376V45.0047H13.5992L25.862 5.99197H18.3524L6.60357 44.9376Z" fill="#105739"/>
              </svg>
            </div>
            <div className={styles.footerNav}>
                <div className={styles.itemBlock} >
                  <div className={`${styles.navTitle} ${styles.navTitleBold}`}>{t('footer.address.title')}</div>
                  <div className={styles.navTitle}>{t('footer.address')}</div>
                </div>
                <div className={styles.itemBlock}>
                <div className={`${styles.navTitle} ${styles.navTitleBold}`}>{t('footer-2')}</div>
                  <a href={`mailto:info@${hostname}`} className={styles.navTitle}>
                    info@{hostname}
                  </a>
                </div>
                {/* <div className={styles.itemBlock}>
                  <div className={styles.navTitle}>{t('footer-3')}</div>
                </div>
                <div className={styles.itemBlock}>
                  <div className={styles.navTitle}>{t('footer-4')}</div>
                </div> */}
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.copyright}>{t('footer.rights')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;