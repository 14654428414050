import React from 'react';
import { useTranslation } from "react-i18next";
import image from '../../assets/images/image2.webp';
import styles from './ThirdD.module.scss';

import Accordion from '../Accordion/Accordion';

const ThirdD = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.imgWrap}>
          <img src={image} alt='' />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{t('main.five.title')}</div>
          <Accordion />
        </div>
      </div>
    </div>
  )
}

export default ThirdD;