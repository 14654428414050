import React from "react";
import styles from './About.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image1 from '../../assets/images/image3.webp';
import image2 from '../../assets/images/image4.webp';
import image3 from '../../assets/images/image5.webp';

const About = () => {
  const { t } = useTranslation();
  const list = t('main.six.item4', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className='container'>
        <div className={styles.contentGrid}>
          <div className={styles.item}>
            <Fade right>
              <div className={styles.titlebg}>
                {t('main.six.title')}
              </div>
            </Fade>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>{t('main.six.item1.title')}</div>
            <div className={styles.text}>{t('main.six.item1.text')}</div>
          </div>
          <div className={styles.item}>
            <img src={image1} alt="" />
          </div>
          <div className={styles.item}>
            <img src={image2} alt="" />
          </div>
          <div className={`${styles.item} desk_only`}></div>
          <div className={styles.item}>
            <div className={styles.title}>{t('main.six.item2.title')}</div>
            <div className={styles.text}>{t('main.six.item2.text')}</div>
          </div>
          <div className={`${styles.item} desk_only`}></div>
          <div className={styles.item}>
            <div className={styles.title}>{t('main.six.item3.title')}</div>
            <div className={styles.text}>{t('main.six.item3.text')}</div>
          </div>
          <div className={`${styles.item} desk_only`}></div>
          <div className={styles.item}>
            <img src={image3} alt="" />
          </div>
          <div className={`${styles.item} desk_only`}></div>
          <div className={`${styles.item} ${styles.list}`}>
            {list.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <div className={styles.listItemTitle}>{item.title}</div>
                <div className={styles.listItemText}>{item.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;