import React from "react";
import styles from './SecondD.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import icon from '../../assets/images/quote.svg';
import icon1 from '../../assets/images/icon1.svg';
import icon2 from '../../assets/images/icon2.svg';
import icon3 from '../../assets/images/icon3.svg';

const Banner = () => {
  const { t } = useTranslation();
  const list = t('main.third.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3];

  return (
    <section className={styles.wrap}>
      <div className="container">
        <div className={`${styles.row} ${styles.first}`}>
          <Fade right><div className={styles.title}>
            <div className={styles.icon}>
              <img src={icon} alt="" />
            </div>
            {t('main.second.title')}
            </div>
          </Fade>
          <Fade left><div className={styles.text}><Trans>{t('main.second.text')}</Trans></div></Fade>
        </div>
        <div className={`${styles.row} ${styles.second}`} id="offer">
          <div className={styles.left}>
            <div className={styles.titlebg}>{t('main.third.title')}</div>
          </div>
          <div className={styles.right}>
            <div className={styles.title}><Trans>{t('main.third.subtitle')}</Trans></div>
            <div className={styles.text}><Trans>{t('main.third.text')}</Trans></div>
          </div>
        </div>
        <div className={styles.list}>
          {list.map((item, index) => (
            <Fade bottom duration={500} delay={index * 1000 + 500} key={index}>
              <div className={styles.listItem}>
                <div className={styles.iitemIcon}>
                  <img src={icons[index]} alt="" />
                </div>
                <div className={styles.listItemTitle}>{t(`${item.title}`)}</div>
                <div className={styles.listItemText}>{t(`${item.text}`)}</div>
              </div>
            </Fade>
          ))}
        </div>
        <div className={styles.btnEnd}>
          <a href='#contacts' className={styles.btn}>{t('btn.contactus')}</a>
        </div>
      </div>
    </section>
  );
};

export default Banner;