import HomePage from '../components/HomePage';

export const routes = [
    { path: '/:lang/', element: HomePage },
];

export const headerRoutes = [
    { path: '#offer', text: 'header.offer' },
    { path: '#about', text: 'header.about' },
    { path: '#contacts', text: 'header.contacts' },
];