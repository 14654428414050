import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import styles from './Tabs.module.scss';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const tabContent = t('main.four.titles', { returnObjects: true });
  const list11 = t('main.four.tabs-1', { returnObjects: true });
  const list12 = t('main.four.tabs-2', { returnObjects: true });
  const list13 = t('main.four.tabs-3', { returnObjects: true });
  const list14 = t('main.four.tabs-4', { returnObjects: true });
  const list21 = t('main.four.tabs-5', { returnObjects: true });
  const list22 = t('main.four.tabs-6', { returnObjects: true });
  const list31 = t('main.four.tabs-7', { returnObjects: true });
  const list32 = t('main.four.tabs-8', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.tabButtons}>
          {tabContent.map((tab, index) => (
            <button
              key={index}
              className={index === activeTab ? styles.tabActive : ''}
              onClick={() => handleTabClick(index)}
            >
              {t(`${tab.title}`)}
            </button>
          ))}
        </div>

        <div className={styles.tabsContent}>
          <div className={`${styles.tabItem} ${0 === activeTab ? styles.tabItemActive : ''}  ${styles.tabItemFirst}`}>
            <div className={styles.tabItemBlock}>
              <div className={styles.tabItemTitle}>{t('main.four.tabs-1.title')}</div>
              <div className={styles.tabList}>
                {list11.list.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.tabItemBlock}>
              <div className={styles.tabItemTitle}>{t('main.four.tabs-2.title')}</div>
              <div className={styles.tabList}>
                {list12.list.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.tabItemBlock}>
              <div className={styles.tabItemTitle}>{t('main.four.tabs-3.title')}</div>
              <div className={styles.tabList}>
                {list13.list.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.tabItemBlock}>
              <div className={styles.tabItemTitle}>{t('main.four.tabs-4.title')}</div>
              <div className={styles.tabList}>
                {list14.list.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${1 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemBlock}>
              <div className={styles.tabItemTitle}>{t('main.four.tabs-5.title')}</div>
              <div className={styles.tabList}>
                {list21.list.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${styles.tabItemBlock} ${styles.mont}`}>
              <div className={styles.tabList}>
                {list22.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${2 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.tabItemBlock}>
              <div className={styles.tabItemTitle}>{t('main.four.tabs-7.title')}</div>
              <div className={styles.tabList}>
                {list31.list.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${styles.tabItemBlock} ${styles.mont}`}>
              <div className={styles.tabList}>
                {list32.map((item, index) => (
                  <div className={styles.listItem} key={index}>
                    <div className={styles.contentText}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;